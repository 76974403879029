import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import css from './EventsDashboard.module.css'
import { useEventContext } from '../../../../../../context/EventManagerContext'
import EventVisibilityToggle from './EventVisibilityToggle'

const EventsDashboard = ({ showActions = true }) => {
   const {
      events,
      fetchEvents,
      handleEditEvent,
      handleDeleteEvent,
      toggleEventVisibility,
      isEventVisible,
   } = useEventContext()

   useEffect(() => {
      fetchEvents()
   }, [fetchEvents])

   const stripHtml = (html) => {
      const temporalDivElement = document.createElement('div')
      temporalDivElement.innerHTML = html
      return (
         temporalDivElement.textContent || temporalDivElement.innerText || ''
      )
   }

   const formatDate = (dateString) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(dateString).toLocaleDateString('pt-PT', options)
   }

   return (
      <div>
         <EventVisibilityToggle
            isVisible={isEventVisible}
            setIsVisible={toggleEventVisibility}
         />
         <div className={css.dashboardContainer}>
            {events &&
               events?.map((event, index) => (
                  <div key={index} className={css.eventCard}>
                     <img
                        src={
                           event.bannerImage ||
                           require('../../../../../../assets/fallback.jpg')
                        }
                        alt={event.name}
                        className={css.eventImage}
                        onError={(e) => {
                           e.target.onerror = null
                           e.target.src = require('../../../../../../assets/fallback.jpg')
                        }}
                     />
                     <div className={css.eventContent}>
                        <h3 className={css.eventName}>
                           <b>Título:</b> {event.name}
                        </h3>
                        <p className={css.eventDescription}>
                           <b>Descrição:</b> {stripHtml(event.description)}
                        </p>
                        <p className={css.eventDate}>
                           <b>Data:</b> {formatDate(event.date)}
                        </p>
                        <a
                           href={event.link}
                           className={css.eventLink}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Link para o evento
                        </a>
                     </div>
                     {showActions && (
                        <div className={css.actionButtons}>
                           <Button
                              label="Editar"
                              onClick={() => handleEditEvent(event._id)}
                              severity="secondary"
                           />
                           <Button
                              label="Apagar"
                              onClick={() => handleDeleteEvent(event._id)}
                              severity="danger"
                           />
                        </div>
                     )}
                  </div>
               ))}
         </div>
      </div>
   )
}

export default EventsDashboard
