import React, { useState } from 'react'
import { Outlet, NavLink, useLocation } from 'react-router-dom'
import Navbar from '../components/navbar/Navbar'
import Banner from '../components/Banner'
import Footer from './Footer'
import MobileNavPopup from './MobileNavPopup'
import css from './Layout.module.css'

function Layout() {
   const [isMobileNavOpen, setMobileNavOpen] = useState(false)
   const location = useLocation()

   // Hide the banner for all routes under /dashboard/regular/financialHealth
   const shouldHideBanner = location.pathname.startsWith('/dashboard/regular/')

   return (
      <>
         <Navbar className={css.navbar} />

         {!shouldHideBanner && <Banner />}
         <div className={css.tabContainer}>
            <div
               className={css.hamburgerMenu}
               onClick={() => setMobileNavOpen(true)}
            >
               <span className={css.hamburgerIcon}>&#9776;</span>
               <span className={css.menuLabel}>Menu</span>
            </div>

            {/* Desktop Navigation */}
            <nav className={css.tabNavigation}>
               <NavLink
                  to="/home"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
                  end
               >
                  Página Inicial
               </NavLink>
               <NavLink
                  to="/criadoresfinanceiros"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Educadores
               </NavLink>
               <NavLink
                  to="/eventosparcerias"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Eventos de Parcerias
               </NavLink>
               <NavLink
                  to="/ferramentas/investingTools/etf"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Ferramentas
               </NavLink>
               <NavLink
                  to="/glossario"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Glossário
               </NavLink>
               <NavLink
                  to="/cursos"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Cursos e Formações
               </NavLink>
               {/* <NavLink
                  to="/artigos"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Artigos
               </NavLink> */}
               <NavLink
                  to="/livros"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Literacia Financeira
               </NavLink>
            </nav>

            {/* Mobile Navigation Popup */}
            <MobileNavPopup
               isOpen={isMobileNavOpen}
               onClose={() => setMobileNavOpen(false)}
            />
         </div>

         <div className={css.tabContainer}>
            <Outlet />
            <Footer />
         </div>
      </>
   )
}

export default Layout
