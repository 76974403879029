import React, { Suspense, useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import styles from '../Playlist.module.css'
import { usePlaylist } from '../../../context/PlaylistContext'
import PlaylistSlider from '../PlaylistSlider'

const UserPlaylistsList = () => {
   const { allPlaylists, fetchPlaylists, isLoading } = usePlaylist()
   const [fetchError, setFetchError] = useState(false)

   useEffect(() => {
      const loadPlaylists = async () => {
         const success = await fetchPlaylists()
         if (!success) {
            setFetchError(true)
         }
      }

      loadPlaylists()
   }, [fetchPlaylists])

   if (isLoading) {
      return <p>A carregar as playlists...</p>
   }

   if (fetchError) {
      return <p>Houve erro no carregamento das playlists.</p>
   }

   if (!allPlaylists.length) {
      return (
         <div className={styles.noPlaylistsMessage}>
            <h2>Não existem Playlists.</h2>
            <p>
               Ainda não criou nenhuma playlist. Comece por criar a sua primeira
               playlist!
            </p>
         </div>
      )
   }

   return (
      <div>
         <h2 className={styles.headerTitle}>As minhas Playlists</h2>
         {allPlaylists.map((playlist, index) => (
            <Row key={index}>
               <Suspense fallback={<div>A carregar playlist...</div>}>
                  <PlaylistSlider playlist={playlist} />
               </Suspense>
            </Row>
         ))}
      </div>
   )
}

export default UserPlaylistsList
