import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Default styles
import styles from './PlaylistSlider.module.css' // Custom styles

const PlaylistSlider = ({ playlist }) => {
   const getEmbedUrl = (videoId) => {
      return `https://www.youtube.com/embed/${videoId}?autoplay=0&mute=1`
   }

   if (!Array.isArray(playlist.videos) || playlist.videos.length === 0) {
      return null
   }

   return (
      <div className={styles.playlistContainer}>
         <h3 className={styles.playlistTitle}>{playlist.name}</h3>
         <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            infiniteLoop={false}
            centerMode={true}
            centerSlidePercentage={33}
         >
            {playlist.videos.map((videoId, idx) => (
               <div key={idx} className={styles.carouselSlide}>
                  <iframe
                     title={`video-${idx}`}
                     src={getEmbedUrl(videoId)}
                     frameBorder="0"
                     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                     style={{ borderRadious: '10px !important' }}
                  />
               </div>
            ))}
         </Carousel>
      </div>
   )
}

export default PlaylistSlider
