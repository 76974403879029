import React from 'react'
import { FileItem } from './FileItem'
import css from './FilesGrid.module.css'

export const FilesGrid = ({
   files,
   onDelete,
   showActions = true,
   fetchFiles,
}) => {
   // Render a loading or placeholder state if files are undefined or an empty array
   if (!files || files.length === 0) {
      return <div>No files available or loading...</div> // Handle as needed
   }

   return (
      <div className={css.filesGridContainer}>
         {files.map((file, index) => (
            <div key={index}>
               <FileItem
                  key={file._id}
                  file={file}
                  showActions={showActions}
                  onDelete={onDelete}
                  fetchFiles={fetchFiles}
               />
            </div>
         ))}
      </div>
   )
}
