import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Col, Row } from 'reactstrap'
import css from '../DashboardRegular.module.css' // Adjust path as necessary
import { useDashboard } from '../../../context/DashboardContext'
import { UserContext } from '../../../context/useUserContext'

const socialMediaIcons = {
   youtube: 'fab fa-youtube',
   facebook: 'fab fa-facebook',
   instagram: 'fab fa-instagram',
   spotify: 'fab fa-spotify',
   tiktok: 'fab fa-tiktok',
   twitter: 'fab fa-twitter',
   telegram: 'fab fa-telegram',
   linkedin: 'fab fa-linkedin',
   pinterest: 'fab fa-pinterest',
   snapchat: 'fab fa-snapchat-ghost',
   reddit: 'fab fa-reddit',
   discord: 'fab fa-discord',
   twitch: 'fab fa-twitch',
   wechat: 'fab fa-weixin',
   vimeo: 'fab fa-vimeo',
   medium: 'fab fa-medium',
}
export default function CreatorInfo() {
   const {
      formData,
      handleChange,
      handleSubmit,
      socialMediaLinks,
      handleLinkChange,
      handleAddLink,
      newLink,
      setFormData,
   } = useDashboard()
   const { user, setUser } = useContext(UserContext)

   const handleFileChange = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('image', file)

      const uploadUrl = `${process.env.REACT_APP_API_URL}/users/uploadCreatorImage`

      try {
         const response = await fetch(uploadUrl, {
            method: 'POST',
            body: formData,
         })

         if (response.ok) {
            const data = await response.json()
            setFormData((prevFormData) => ({
               ...prevFormData,
               profilePictureUrl: data.url,
            }))
         } else {
            console.error('Upload failed:', await response.text())
         }
      } catch (error) {
         console.error('Upload error:', error)
      }
   }

   return (
      <div>
         <form onSubmit={handleSubmit}>
            <Row style={{ display: 'flex' }} className={css.tabContainer}>
               <Col xs={6}>
                  <div className={css.tituloResultadoTitulos}>
                     Informações sobre o criador de conteúdos
                  </div>
                  <div className={css.alinhaDadosCreator}>
                     <input type="file" onChange={handleFileChange} />
                     {formData.profilePictureUrl && (
                        <img
                           src={formData.profilePictureUrl}
                           alt="Profile"
                           className={css.imageProfile}
                        />
                     )}
                  </div>
                  <div style={{ display: 'flex' }}>
                     <Col className={css.alinhaDadosCreator}>
                        <div>
                           <div className={css.subtituloExplicar}>
                              Username:
                           </div>
                           <InputText
                              type="text"
                              name="username"
                              value={formData.username}
                              onChange={handleChange}
                           />
                        </div>
                        <div>
                           <div className={css.subtituloExplicar}>Nome:</div>
                           <InputText
                              type="text"
                              name="firstname"
                              value={formData.firstname}
                              onChange={handleChange}
                           />
                        </div>
                        <div>
                           <div className={css.subtituloExplicar}>
                              Último Nome:
                           </div>
                           <InputText
                              type="text"
                              name="lastname"
                              value={formData.lastname}
                              onChange={handleChange}
                           />
                        </div>
                        <Button type="submit" label="Guardar Alterações" />
                     </Col>
                     <Col>
                        <div>
                           <div className={css.subtituloExplicar}>Email:</div>
                           <InputText
                              type="text"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                           />
                        </div>
                        <div>
                           <div className={css.subtituloExplicar}>Temas:</div>
                           <InputText
                              type="text"
                              name="topics"
                              value={formData.topics.join(', ')}
                              onChange={handleChange}
                           />
                        </div>
                        <div>
                           <div className={css.subtituloExplicar}>
                              Tipo de Conta:
                           </div>
                           <InputText
                              type="text"
                              name="role"
                              value={formData.role}
                              onChange={handleChange}
                              disabled
                           />
                        </div>
                     </Col>
                  </div>
               </Col>
               <Col xs={6}>
                  <div className={css.tituloResultadoTitulos}>
                     Descrição do canal e redes sociais
                  </div>
                  <div className={css.tituloResultadoCards}>
                     <div style={{ marginBottom: '10px' }}>
                        Biográfia / Descrição
                     </div>
                     <InputTextarea
                        name="bio"
                        value={formData.bio}
                        onChange={handleChange}
                        autoResize
                        rows={5}
                        cols={50}
                     />
                  </div>
                  <div className={css.tituloResultadoCards1}>
                     <div style={{ marginTop: '10px' }}>
                        Links das Redes Sociais:
                     </div>
                     <InputText
                        type="text"
                        value={newLink}
                        onChange={handleLinkChange}
                        style={{ marginTop: '10px' }}
                     />
                     <Button
                        label="Adicionar Rede Social"
                        onClick={handleAddLink}
                        style={{ marginTop: '10px', marginBottom: '20px' }}
                     />
                  </div>
                  {socialMediaLinks.map((link, index) => (
                     <div key={index} className={css.tituloResultadoCards}>
                        <a
                           href={link.link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           <i
                              className={`${socialMediaIcons[link.type]}`}
                              style={{ color: 'white', marginRight: '10px' }}
                           ></i>
                        </a>
                        <InputText type="text" value={link.link} readOnly />
                     </div>
                  ))}
               </Col>
            </Row>
         </form>
      </div>
   )
}
