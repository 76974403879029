import React, { useContext, useState, useEffect } from 'react'
import css from '../../pages/Layout.module.css'
import { UserContext } from '../../context/useUserContext'
import Avatar from './Avatar'
import useUserNavigation from '../../hooks/useUserNavigation'
import UserActions from './navBarComponents/UserActions'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'

const Navbar = () => {
   const { user, setUser } = useContext(UserContext)
   const { navigateToDashboard } = useUserNavigation()
   const [isNavbarVisible, setIsNavbarVisible] = useState(false)
   const [isAtTop, setIsAtTop] = useState(true)

   useEffect(() => {
      const handleScroll = () => {
         setIsAtTop(window.scrollY < 50)
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
   }, [])

   const logoutUser = () => {
      setUser((prev) => ({ ...prev, login: false }))
      navigateToDashboard()
   }

   const toggleNavbar = () => {
      setIsNavbarVisible(!isNavbarVisible)
      if (!isAtTop) {
         window.scrollTo(0, 0)
      }
   }

   const dashboardPath =
      {
         AdminUser: '/dashboard/AdminUser',
         RegularUser: '/dashboard/regular/favorites',
         CreatorUser: '/dashboard/creator/info',
      }[user?.role] || '/dashboard'

   const renderUserProfileLink = () => (
      <Link to={dashboardPath} className="linkDecoration">
         <div className={css.flexCenter}>
            <Avatar imageUrl={user.profilePictureUrl} />
            <div className={css.userInfo}>
               <div className="userAvatar">{user.username}</div>
               <UserActions
                  onEdit={navigateToDashboard}
                  onLogout={logoutUser}
               />
            </div>
         </div>
      </Link>
   )

   return (
      <div>
         <Button
            icon={isAtTop ? 'pi pi-bars' : 'pi pi-arrow-up'}
            onClick={toggleNavbar}
            className={css.menuButton}
            aria-label={isAtTop ? 'Toggle Menu' : 'Go to Top'}
         />
         {isNavbarVisible && (
            <div className={`${css.paddedContainer} ${css.navbarVisible}`}>
               <div className={css.navContent}>{renderUserProfileLink()}</div>
            </div>
         )}
      </div>
   )
}

export default Navbar
