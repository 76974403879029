// useUserNavigation.js
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context/useUserContext'

const useUserNavigation = () => {
   const { user } = useContext(UserContext)
   const navigate = useNavigate()

   const navigateToDashboard = () => {
      let path = ''
      switch (user.role) {
         case 'RegularUser':
            path = user.isPremium
               ? '/dashboard/premium'
               : '/dashboard/regular/favorites'
            break
         case 'CreatorUser':
            path = '/dashboard/creator'
            break
         case 'Mod':
            path = '/dashboard/moderator'
            break
         case 'AdminUser':
            path = '/dashboard/AdminUser'
            break
         default:
            path = '/login' // Fallback path
            break
      }
      navigate(path)
   }

   return { navigateToDashboard }
}

export default useUserNavigation
