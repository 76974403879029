import React, { createContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import api from '../utils/api'

export const UserContext = createContext({})

function UserProvider({ children }) {
   const [cookies, setCookie, removeCookie] = useCookies(['user'])
   const [isLoading, setIsLoading] = useState(true)
   const [user, setUser] = useState(() => {
      const storedUserData = localStorage.getItem('userData')
      return storedUserData ? JSON.parse(storedUserData) : null
   })

   const fetchUserData = async () => {
      try {
         const response = await api.get('/authRoutes/me')
         if (response.status === 200) {
            setUser(response.data.user)
         }
      } catch (error) {
         console.error('Error fetching user data:', error)
         logout() // Log the user out if fetching fails (e.g., token expired)
      }
   }

   useEffect(() => {
      const token = localStorage.getItem('token')
      if (token) {
         api.defaults.headers.common['Authorization'] = `Bearer ${token}`
         fetchUserData()
      }
      setIsLoading(false)
   }, [])

   const logout = () => {
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      removeCookie('token', { path: '/', sameSite: 'None', secure: true })
      removeCookie('login', { path: '/', sameSite: 'None', secure: true })
      setUser(null)
   }

   return (
      <UserContext.Provider
         value={{
            user,
            isLoading,
            setUser,
            logout,
         }}
      >
         {isLoading ? <div>Loading...</div> : children}
      </UserContext.Provider>
   )
}

export default UserProvider
