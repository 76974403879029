import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import css from './../../../../Layout.module.css'

function EventsTabPanel() {
   return (
      <div>
         <Row className={css.tabContainer2}>
            <Col>
               <nav className={css.tabNavigation}>
                  <NavLink
                     to="big-events-dashboard" // Relative path
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Dashboard Eventos BIG
                  </NavLink>
                  <NavLink
                     to="create-event" // Relative path
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Criar eventos na minha Página
                  </NavLink>
                  <NavLink
                     to="my-events-dashboard" // Relative path
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Dashboard Eventos na minha Página
                  </NavLink>
               </nav>
            </Col>
         </Row>
         <Outlet />
      </div>
   )
}

export default EventsTabPanel
