import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'
import { ProgressSpinner } from 'primereact/progressspinner'

export default function AnnouncementCreator({
   fetchAnnouncements,
   setAnnouncements,
   announcements,
}) {
   const { user } = useContext(UserContext)
   const [isLoading, setIsLoading] = useState(false)
   const [formError, setFormError] = useState('')
   const canCreateAnnouncement = announcements?.length < 3
   const userId = user.id

   const formik = useFormik({
      initialValues: {
         announcement: '',
      },
      onSubmit: async (values, { resetForm }) => {
         const newAnnouncement = {
            text: values.announcement
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;'),
            creatorId: userId,
         }

         // Optimistically update UI
         const optimisticUpdate = [...announcements, newAnnouncement]
         setAnnouncements(optimisticUpdate)

         setIsLoading(true)
         try {
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}announcementRoutes/`,
               newAnnouncement,
               {
                  headers: { Authorization: `Bearer ${user.accessToken}` },
               }
            )
            // Confirm update
            setAnnouncements(response.data)
            toast.success('Anúncio criado com sucesso!')
            resetForm()
         } catch (error) {
            // Revert optimistic update
            setAnnouncements(announcements)
            handleError(error)
         } finally {
            setIsLoading(false)
         }
      },
      validate: (values) => {
         const errors = {}
         if (values.announcement.length > 255) {
            errors.announcement = 'Anúncio deve conter no máximo 255 caracteres'
         }
         if (!values.announcement) {
            errors.announcement = 'Announcement cannot be empty.'
         }
         if (/<(“[^”]*”|'[^’]*’|[^'”>])*>/.test(values.announcement)) {
            errors.announcement = 'Tags de HTML não são permitidas '
         }
         return errors
      },
   })

   return (
      <div>
         {canCreateAnnouncement ? (
            <form onSubmit={formik.handleSubmit}>
               <InputTextarea
                  id="announcement"
                  name="announcement"
                  value={formik.values.announcement}
                  onChange={formik.handleChange}
                  rows={3}
                  cols={40}
                  autoResize={true}
                  maxLength={255}
                  placeholder="What would you like to announce?"
                  disabled={isLoading}
               />
               {formik.errors.announcement && (
                  <div className="p-error">{formik.errors.announcement}</div>
               )}
               {formError && <div className="p-error">{formError}</div>}
               <div>
                  <span>{formik.values.announcement.length}/255</span>
               </div>
               <br></br>
               {isLoading ? (
                  <ProgressSpinner style={{ width: '50px', height: '50px' }} />
               ) : (
                  <Button
                     type="submit"
                     disabled={!!formik.errors.announcement}
                     label="Announce"
                  />
               )}
            </form>
         ) : (
            <p>Atingiu o limite de 3 anúncios por utilizador.</p>
         )}
      </div>
   )
}
