import React, { useContext } from 'react'
import axios from 'axios'

import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'

const AnnouncementVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = useContext(UserContext)

   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { announcements: newVisibility } },
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility)
         toast.success('Visibilidade foi alterada com sucesso!')
      } catch (error) {
         handleError(error)
      }
   }

   return (
      <label>
         Mostrar Anúncios:
         <input
            type="checkbox"
            checked={isVisible}
            onChange={toggleVisibility}
         />
      </label>
   )
}

export default AnnouncementVisibilityToggle
