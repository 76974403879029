import React from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../ToolIndex.module.css'

function InvestingTools() {
   return (
      <div>
         <Row className={css.tabContainer}>
            <div className={css.tabContainer}>
               <nav className={css.tabNavigation}>
                  <NavLink
                     to="/ferramentas/investingTools/etf"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Análise de ETFs
                  </NavLink>
                  <NavLink
                     to="/ferramentas/investingTools/reits"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Valor Intrínseco de REITs
                  </NavLink>
               </nav>
            </div>
            <div className={css.tabContent}>
               <Outlet /> {/* This will render the specific tool if selected */}
            </div>
         </Row>
      </div>
   )
}

export default InvestingTools
