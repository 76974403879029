import React from 'react'
import css from './FileItem.module.css'
import { Button } from 'primereact/button'

export const FileItem = ({ file, onDelete, showActions }) => {
   const downloadUrl = `${process.env.REACT_APP_API_URL}fileRoutes/download/${file._id}`

   const handleDeleteClick = async () => {
      if (
         window.confirm(
            `Are you sure you want to delete "${file.originalName}"?`
         )
      ) {
         try {
            onDelete(file._id)
         } catch (error) {
            console.error('Error in handleDeleteClick:', error)
         }
      }
   }
   const fileImageSrc =
      file.mimeType === 'application/pdf' ? 'pdfFiles.webp' : 'excelFiles.webp'

   return (
      <div className={css.fileItemCard}>
         <img
            src={require(`../../../../../assets/files/${fileImageSrc}`)}
            alt={file.originalName}
            className={css.fileItemImage}
         />
         <div className={css.fileItemContent}>
            <h4 className={css.fileItemTitle}>{file.cleanOriginalName}</h4>
            <a href={downloadUrl} className={css.fileItemDownload} download>
               Descarregar
            </a>
            {showActions && (
               <Button
                  onClick={handleDeleteClick}
                  className={css.fileItemDelete}
                  label="Apagar"
                  icon="pi pi-trash"
                  size="small"
                  severity="danger"
               />
            )}
         </div>
      </div>
   )
}
