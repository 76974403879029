import React, { useContext, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'

const CourseVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = useContext(UserContext)
   const [isLoading, setIsLoading] = useState(false)

   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      setIsLoading(true)
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { courses: newVisibility } },
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility)
         toast.success('Visibility of courses has been successfully updated!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to update course visibility.')
         setIsVisible(isVisible)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <div>
         <label>
            Mostrar Cursos:
            <input
               type="checkbox"
               checked={isVisible}
               onChange={toggleVisibility}
            />
         </label>
      </div>
   )
}

export default CourseVisibilityToggle
