import React from 'react'
import css from './Avatar.module.css'

const Avatar = ({ imageUrl }) => {
   return (
      <div className={css.avatar}>
         <img src={imageUrl} alt="User Avatar" className={css.avatarImage} />
      </div>
   )
}

export default Avatar
