import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { UserContext } from '../../../../../context/useUserContext'
import { toast } from 'react-toastify'
import AnnouncementItem from './AnnouncementItem'
import AnnouncementVisibilityToggle from './AnnouncementVisibilityToggle'
import { Col, Row } from 'reactstrap'
import AnnouncementCreator from './AnnouncementCreator'
import { Dialog } from 'primereact/dialog' // Import Dialog
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import css from './AnnouncementStyles.module.css'
import { handleError } from '../../../../../utils/handleError'

export default function AnnouncementManager() {
   const { user } = useContext(UserContext)
   const [announcements, setAnnouncements] = useState([])
   const [isVisible, setIsVisible] = useState(true)
   const [editDialog, setEditDialog] = useState(false) // Dialog visibility
   const [currentAnnouncement, setCurrentAnnouncement] = useState(null) // Currently edited announcement
   const creatorId = user.id
   const [fetchError, setFetchError] = useState(false)

   useEffect(() => {
      fetchAnnouncements()
   }, [])
   const retryFetch = () => {
      setFetchError(false)
      fetchAnnouncements()
   }
   useEffect(() => {
      async function fetchAnnouncementVisibility() {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`
            )

            setIsVisible(response.data.announcements)
         } catch (error) {
            handleError(error)
            toast.error('Failed to fetch file visibility.')
         }
      }
      fetchAnnouncementVisibility()
   }, [user.id])

   const fetchAnnouncements = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}announcementRoutes/${creatorId}`,
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         if (Array.isArray(response.data)) {
            setAnnouncements(response.data)
         } else {
            console.error('Data is not an array', response.data)
            setAnnouncements([]) // Fallback to an empty array
         }
      } catch (error) {
         handleError(error)
         setFetchError(true)
      }
   }

   const handleEdit = (announcement) => {
      setCurrentAnnouncement({ ...announcement }) // Clone the announcement to edit
      setEditDialog(true) // Open the dialog
   }

   const saveEditedAnnouncement = async () => {
      try {
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}announcementRoutes/${currentAnnouncement._id}`,
            { text: currentAnnouncement.text },
            {
               headers: {
                  UserID: user.id,
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         if (response.status === 200) {
            toast.success('Anúncio atualizado com sucesso!')
            fetchAnnouncements()
            setEditDialog(false)
         }
      } catch (error) {
         handleError(error)
      }
   }

   const onAnnouncementTextChange = (e) => {
      setCurrentAnnouncement((prev) => ({ ...prev, text: e.target.value }))
   }
   const deleteAnnouncement = async (id) => {
      if (!window.confirm('Tem a certeza que quer apagar este anúncio?')) return

      try {
         const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}announcementRoutes/${id}`,
            {
               headers: {
                  UserID: user.id, // Assuming 'user' is the logged-in user object
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         if (response.status === 200) {
            toast.success('Anúncio apagado com sucesso!')
            fetchAnnouncements()
         }
      } catch (error) {
         handleError(error)
      }
   }
   const DialogHeader = ({ title }) => {
      return <div style={{ color: 'white' }}>{title}</div>
   }
   if (fetchError) {
      return (
         <div className="error-view">
            <p>Failed to load announcements. Please try again.</p>
            <Button label="Retry" icon="pi pi-refresh" onClick={retryFetch} />
         </div>
      )
   }

   console.log('announcements', announcements)
   return (
      <>
         <Row style={{ display: 'flex' }} className={css.tabContainer}>
            <Col xs={7}>
               <h2>Gerir Anúncios</h2>
               <AnnouncementVisibilityToggle
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  // toggleVisibility={() => setIsVisible(!isVisible)}
               />
               <h3 className={css.emDestaque}>Em Destaque</h3>
               <div className={css.cardContainer}>
                  {announcements.map((announcement, index) => (
                     <AnnouncementItem
                        key={announcement._id}
                        announcement={announcement}
                        onEdit={handleEdit}
                        onDelete={() => deleteAnnouncement(announcement._id)}
                     />
                  ))}
               </div>
            </Col>
            <Col>
               <h2>Criar Anúncios</h2>
               <AnnouncementCreator
                  fetchAnnouncements={fetchAnnouncements}
                  setAnnouncements={setAnnouncements}
                  announcements={announcements}
               />
            </Col>
         </Row>
         <Dialog
            header={<DialogHeader title="Editar Anúncio" />}
            visible={editDialog}
            style={{ width: '30vw', display: 'flex', flexDirection: 'column' }}
            onHide={() => setEditDialog(false)}
         >
            <div className={css.dialogContent}>
               <Col>
                  <InputTextarea
                     value={currentAnnouncement?.text}
                     onChange={onAnnouncementTextChange}
                     rows={5}
                     cols={30}
                     style={{ width: '100%' }}
                  />
               </Col>
               <Col>
                  <Button
                     label="Guardar"
                     icon="pi pi-check"
                     onClick={saveEditedAnnouncement}
                     style={{ marginTop: '10px' }}
                  />
               </Col>
            </div>
         </Dialog>
      </>
   )
}
