import axios from 'axios'

// Function to refresh the token
async function refreshToken() {
   const refreshToken = localStorage.getItem('refreshToken')
   if (!refreshToken) {
      throw new Error('No refresh token available')
   }

   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_URL}/authRoutes/refresh-token`,
         { refreshToken }
      )
      if (response.status === 200) {
         const { accessToken } = response.data
         localStorage.setItem('token', accessToken)

         return accessToken
      } else {
         throw new Error('Unable to refresh token')
      }
   } catch (error) {
      console.error('Error during token refresh:', error)
      throw error // Rethrowing the error to be handled by the caller
   }
}

const api = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      'Content-Type': 'application/json',
   },
})

// Request Interceptor to add Authorization header
api.interceptors.request.use(
   (config) => {
      const token = localStorage.getItem('token')
      if (token) {
         config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
   },
   (error) => Promise.reject(error)
)

// Response Interceptor to handle token expiration and retry failed requests
api.interceptors.response.use(
   (response) => response, // Return response if successful
   async (error) => {
      const originalRequest = error.config

      // If the request returns 401 (unauthorized) and we haven't already retried
      if (
         error.response &&
         error.response.status === 401 &&
         !originalRequest._retry
      ) {
         originalRequest._retry = true

         try {
            const newToken = await refreshToken() // Attempt to refresh token
            axios.defaults.headers.common[
               'Authorization'
            ] = `Bearer ${newToken}`
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`
            return api(originalRequest) // Retry the original request with the new token
         } catch (refreshError) {
            console.error('Token refresh failed:', refreshError)
            window.location.href = '/login' // Redirect to login on failure
         }
      }

      return Promise.reject(error) // Return error if it's not a token expiration issue
   }
)

export default api
