import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import CoursesList from './CourseList'
import CourseForm from './CourseForm'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import css from './Courses.module.css'
import { Col, Row } from 'reactstrap'
import CourseVisibilityToggle from './CourseVisibilityToggle'
import { handleError } from '../../../../../utils/handleError'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'

function CourseManagement() {
   const { user } = useContext(UserContext)
   const [courses, setCourses] = useState([])
   const [editingCourse, setEditingCourse] = useState(null)
   const [isDialogShown, setIsDialogShown] = useState(false)
   const [isCoursesVisible, setIsCoursesVisible] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)

   const fetchCourses = async () => {
      setIsLoading(true)
      setError(null)
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}courseRoutes/user/${user.id}`,
            {
               headers: { Authorization: `Bearer ${user.accessToken}` },
            }
         )
         setCourses(response.data)
      } catch (error) {
         handleError(error)
         toast.error('Failed to load courses.')
         setError(error)
      } finally {
         setIsLoading(false)
      }
   }

   const createCourse = async (courseData) => {
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/courseRoutes/`,
            courseData,
            {
               headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )

         setCourses((prevCourses) => [...prevCourses, response.data.course])
         toast.success('Course added successfully.')
      } catch (error) {
         handleError(error)
         toast.error('Failed to add course.')
      }
   }
   const onDeleteCourse = async (courseId) => {
      if (!window.confirm('Tem a certeza que quer apagar este curso?')) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/courseRoutes/${courseId}`,
            {
               headers: { Authorization: `Bearer ${user.accessToken}` },
            }
         )
         // Remove the course from the local state on successful deletion
         setCourses((prevCourses) =>
            prevCourses.filter((course) => course._id !== courseId)
         )
         toast.success('Course deleted successfully.')
      } catch (error) {
         handleError(error)
         toast.error('Failed to delete course.')
      }
   }
   useEffect(() => {
      fetchCourses()
   }, [user.id])

   const handleAddCourse = () => {
      setEditingCourse(null)
      setIsDialogShown(true)
   }

   const handleEditCourse = (course) => {
      setEditingCourse(course)
      setIsDialogShown(true)
   }

   const onResetEditing = () => {
      setEditingCourse(null)
      setIsDialogShown(false)
   }

   const handleUpdateCourse = async (courseId, courseData) => {
      try {
         const url = `${process.env.REACT_APP_API_URL}/courseRoutes/${courseId}`
         const response = await axios.put(url, courseData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: `Bearer ${user.accessToken}`,
            },
         })
         fetchCourses()
         toast.success('Course updated successfully.')
      } catch (error) {
         handleError(error)
         toast.error('Failed to update course.')
      }
   }

   const renderKey = courses.length

   if (isLoading) {
      return <ProgressSpinner />
   }

   if (error) {
      return <p>Error loading courses: {error.message}</p>
   }

   return (
      <section className={css.tabContainer} key={renderKey}>
         <h1>Gestão de Cursos</h1>
         <CourseVisibilityToggle
            isVisible={isCoursesVisible}
            setIsVisible={setIsCoursesVisible}
         />
         <Row>
            <Col>
               <h2>Adicionar novo curso</h2>
               <Button label="Adicionar" onClick={handleAddCourse} />
            </Col>
         </Row>
         <Row style={{ marginTop: '30px' }}>
            <h2>Os Meus Cursos</h2>
            <CoursesList
               courses={courses}
               onEditCourse={handleEditCourse}
               onDeleteCourse={onDeleteCourse}
               onResetEditing={onResetEditing}
               isLoading={isLoading}
               error={error}
            />
            <Dialog
               header={editingCourse ? 'Editar Curso' : 'Adicionar Curso'}
               visible={isDialogShown}
               style={{ width: '50vw' }}
               onHide={onResetEditing}
               headerClassName={css.dialogHeader}
            >
               <CourseForm
                  existingCourse={editingCourse}
                  onClose={onResetEditing}
                  coursesCount={courses.length}
                  updateCourses={setCourses}
                  createCourse={createCourse}
                  handleUpdateCourse={handleUpdateCourse}
               />
            </Dialog>
         </Row>
      </section>
   )
}

export default CourseManagement
