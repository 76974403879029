import YouTubePlayer from '../../../../../../components/youtubeCarousels/YoutubePlayer'

const VideoDisplay = ({ videoLink }) => {
   const getVideoId = (url) => {
      const regex =
         /(?:https:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      const match = url.match(regex)
      return match ? match[1] : null
   }

   const videoId = getVideoId(videoLink)
   if (!videoId) {
      return <p>Link do YouTube inválido. Verifique o link e tente de novo.</p>
   }

   return <YouTubePlayer videoId={videoId} setWidth={550} setHeight={300} />
}

export default VideoDisplay
