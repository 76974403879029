import React from 'react'
import { InputText, Dropdown, Button } from 'primereact'
import { usePlaylist } from '../../../context/PlaylistContext'
import css from './PlaylistForm.module.css' // Assume CSS module for styles
import { Col, Row } from 'react-bootstrap'

const PlaylistForm = () => {
   const {
      playlistName,
      setPlaylistName,
      newVideoLink,
      setNewVideoLink,
      videoLinks,
      handleAddVideo,
      selectedTopic,
      setSelectedTopic,
      topicOptions,
      handleCreatePlaylist,
      clearForm,
      hasMinimumVideos, // Boolean state indicating if the minimum videos requirement is met
   } = usePlaylist()

   const videoCountMessage = () => {
      const message = `${videoLinks.length}/3 vídeos necessários para criar a playlist`
      const isComplete = videoLinks.length >= 3
      return (
         <div
            style={{
               color: isComplete ? 'green' : 'red',
               fontWeight: isComplete ? 'bold' : 'normal',
            }}
         >
            {message}
         </div>
      )
   }
   return (
      <div className={css.playlistFormContainer}>
         <div className={css.subtituloResultado}>Criar Playlist</div>
         <Row style={{ display: 'flex' }}>
            <Col xs={6} style={{ display: 'grid' }}>
               <InputText
                  value={playlistName}
                  onChange={(e) => setPlaylistName(e.target.value)}
                  placeholder="Nome da Playlist"
                  className={css.inputText}
               />
               <Dropdown
                  value={selectedTopic}
                  options={topicOptions}
                  onChange={(e) => setSelectedTopic(e.value)}
                  placeholder="Selecionar Tema"
                  className={css.dropdown}
               />
               <InputText
                  value={newVideoLink}
                  onChange={(e) => setNewVideoLink(e.target.value)}
                  placeholder="Introduzir links"
                  className={css.inputText}
               />
               {videoCountMessage()}
               <Button
                  label="Adicionar Vídeos"
                  onClick={handleAddVideo}
                  className={css.button}
               />
            </Col>
         </Row>
         <Row>
            <Button
               label="Criar Playlist"
               onClick={handleCreatePlaylist}
               disabled={!hasMinimumVideos}
               className={css.button}
               style={{ marginRight: '10px' }}
            />

            <Button
               label="Limpar formulário"
               onClick={clearForm}
               className={css.button}
            />
         </Row>
      </div>
   )
}

export default PlaylistForm
