import React from 'react'
import MainPlaylistSelector from './MainPlaylistSelector'
import EditPlaylist from '../EditarPlaylist'
import PlaylistVisibilityToggle from './PlaylistVisibilityToggle'
import css from '../Playlist.module.css'
const PlaylistManager = () => {
   return (
      <div className={css.tabContainer2}>
         <PlaylistVisibilityToggle />
         <MainPlaylistSelector />
         <EditPlaylist />
      </div>
   )
}

export default PlaylistManager
