import React, { useState, useEffect, useRef } from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import EventForm from '../../../../dashBoardAdmin/components/ContentManagementComponents/WebinarsEventsComponents/EventForm'
import { UserContext } from '../../../../../../context/useUserContext'
import './BigEventsDashBoad.css'

function BigEventsDashboard() {
   const { user } = React.useContext(UserContext)
   const [events, setEvents] = useState([])
   const [currentEvent, setCurrentEvent] = useState(null)
   const [editDialogVisible, setEditDialogVisible] = useState(false)
   const [detailsDialogVisible, setDetailsDialogVisible] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)
   const toast = useRef(null)

   useEffect(() => {
      fetchEvents()
   }, [])

   const fetchEvents = async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}adminEvents/createdByMe`, // Update the endpoint accordingly
            {
               method: 'GET',
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         if (!response.ok) {
            throw new Error('Failed to fetch events.')
         }
         const data = await response.json()
         setEvents(data)
      } catch (error) {
         console.error('Error fetching events:', error)
      }
   }

   const openNewEventDialog = () => {
      setCurrentEvent(null)
      setEditDialogVisible(true)
      setDetailsDialogVisible(false)
   }

   const onSaveEvent = async (formData) => {
      const isEdit = currentEvent != null
      const method = isEdit ? 'PUT' : 'POST'
      const endpoint = isEdit
         ? `${process.env.REACT_APP_API_URL}adminEvents/${currentEvent._id}`
         : `${process.env.REACT_APP_API_URL}adminEvents/`

      if (isEdit && currentEvent.status === 'approved') {
         formData.append('status', 'pending')
      }

      try {
         const response = await fetch(endpoint, {
            method: method,
            headers: {
               Authorization: `Bearer ${user.accessToken}`,
            },
            body: formData,
         })

         if (!response.ok) {
            throw new Error('Failed to save event')
         }

         const updatedEvent = await response.json()
         setEvents(
            events.map((e) => (e._id === updatedEvent._id ? updatedEvent : e))
         )
         closeEditDialog(false)
         setCurrentEvent(null)
      } catch (error) {
         console.error('Error saving event:', error)
         toast.current.show({
            severity: 'error',
            summary: 'Saving failed',
            detail: error.message,
            life: 3000,
         })
      }
   }

   const onRowDetails = (rowData) => {
      setCurrentEvent(rowData)
      setDetailsDialogVisible(true)
      setEditDialogVisible(false)
   }

   const onRowEdit = (rowData) => {
      const formattedData = {
         ...rowData,
         start: new Date(rowData.startTime).toISOString(),
         end: new Date(rowData.endTime).toISOString(),
         locationType: rowData.locationType,
         address: rowData.address,
         onlineLink: rowData.onlineLink,
         registrationLink: rowData.registrationLink,
         coverImage: rowData.coverImage,
         eventCreatorName: rowData.eventCreatorName,
         eventCreator: rowData.eventCreator,
         eventCategory: rowData.eventCategory,
         expectedAttendees: rowData.expectedAttendees,
         accessibilityOptions: rowData.accessibilityOptions || [],
         contactEmail: rowData.contactEmail,
         contactPhone: rowData.contactPhone,
         termsAccepted: rowData.termsAccepted,
         // Ensure all fields expected by EventForm are correctly set here
      }

      setCurrentEvent(formattedData)
      setEditDialogVisible(true) // Ensure this dialog is visible
      setDetailsDialogVisible(false)
   }

   const onRowDelete = async (rowData) => {
      if (rowData.status === 'approved') {
         // Handle deletion request for approved events
         if (
            window.confirm(
               `Request deletion for the approved event titled "${rowData.title}"? This will need admin approval.`
            )
         ) {
            try {
               const requestOptions = {
                  method: 'PUT', // Correct method for updating status
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${user.accessToken}`,
                  },
                  body: JSON.stringify({ status: 'deletion requested' }),
               }
               const response = await fetch(
                  `${process.env.REACT_APP_API_URL}adminEvents/requestDeletion/${rowData._id}`,
                  requestOptions
               )
               if (!response.ok) throw new Error('Failed to request deletion')
               toast.current.show({
                  severity: 'info',
                  summary: 'Deletion Requested',
                  detail: 'Deletion request has been sent to admin.',
                  life: 3000,
               })
               fetchEvents() // Refresh the event list to reflect the status change
            } catch (error) {
               console.error('Error requesting deletion:', error)
               toast.current.show({
                  severity: 'error',
                  summary: 'Request Failed',
                  detail: 'Failed to send deletion request.',
                  life: 3000,
               })
            }
            return // Ensure function execution stops here after handling approved event
         }
      } else if (rowData.status === 'pending') {
         // Handle direct deletion for pending events
         if (
            window.confirm(
               `Are you sure you want to delete the pending event titled "${rowData.title}"?`
            )
         ) {
            try {
               const requestOptions = {
                  method: 'DELETE',
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
               const response = await fetch(
                  `${process.env.REACT_APP_API_URL}adminEvents/${rowData._id}`,
                  requestOptions
               )
               if (!response.ok) throw new Error('Failed to delete the event')
               setEvents(events.filter((event) => event._id !== rowData._id)) // Update local state
               toast.current.show({
                  severity: 'success',
                  summary: 'Event Deleted',
                  detail: 'The pending event has been successfully deleted.',
                  life: 3000,
               })
            } catch (error) {
               console.error('Error deleting event:', error)
               toast.current.show({
                  severity: 'error',
                  summary: 'Deletion Failed',
                  detail: 'Error deleting event.',
                  life: 3000,
               })
            }
         }
      } else {
         // Inform the user that deletion is not allowed for other statuses
         toast.current.show({
            severity: 'warn',
            summary: 'Deletion Not Allowed',
            detail:
               'Deletion or requests for deletion are not allowed for this event status.',
            life: 3000,
         })
      }
   }

   const statusBodyTemplate = (rowData) => {
      let statusLabel = rowData.status
      let styleClass = ''

      switch (rowData.status) {
         case 'pending':
            statusLabel = 'Pending Approval'
            styleClass = 'p-tag p-tag-warning'
            break
         case 'approved':
            statusLabel = 'Approved'
            styleClass = 'p-tag p-tag-success'
            break
         case 'deletion requested':
            statusLabel = 'Deletion Requested'
            styleClass = 'p-tag p-tag-danger'
            break
         case 'rejected':
            statusLabel = 'Rejected'
            styleClass = 'p-tag p-tag-danger'
            break
         default:
            statusLabel = 'Unknown'
            styleClass = 'p-tag p-tag-secondary'
            break
      }

      return <span className={styleClass}>{statusLabel}</span>
   }
   const closeEditDialog = () => {
      setCurrentEvent(null)
      setEditDialogVisible(false)
   }

   const closeDetailsDialog = () => {
      setCurrentEvent(null)
      setDetailsDialogVisible(false)
   }
   const actionBodyTemplate = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-info-circle"
               onClick={() => onRowDetails(rowData)}
               tooltip="View Details"
               className="p-button-text"
            />
            <Button
               label="Edit"
               icon="pi pi-pencil"
               onClick={() => onRowEdit(rowData)}
               className="p-button-rounded p-button-success p-mr-2"
            />
            <Button
               label="Delete"
               icon="pi pi-trash"
               onClick={() => onRowDelete(rowData)}
               className="p-button-rounded p-button-warning"
            />
         </React.Fragment>
      )
   }

   return (
      <div>
         <Toast ref={toast} />

         <Card title="Event Submissions Dashboard">
            {isLoading ? (
               <p>Loading...</p>
            ) : (
               <>
                  <Button
                     label="Create New Event"
                     icon="pi pi-plus"
                     onClick={openNewEventDialog}
                     className="p-mb-3"
                  />
                  <DataTable value={events} responsiveLayout="scroll">
                     <Column field="title" header="Title" sortable></Column>
                     <Column field="date" header="Date" sortable></Column>
                     <Column
                        field="status"
                        header="Status"
                        body={statusBodyTemplate}
                     ></Column>
                     <Column
                        field="action"
                        header="Actions"
                        body={actionBodyTemplate}
                     ></Column>
                  </DataTable>
               </>
            )}
            {error && <p>{error}</p>}
         </Card>
         <Dialog
            header={
               <span style={{ color: 'white' }}>
                  {currentEvent ? 'Edit Event Details' : 'Create New Event'}
               </span>
            }
            visible={editDialogVisible}
            onHide={closeEditDialog}
            className="white-text-dialog"
            modal
            style={{ width: '50vw', color: 'white' }}
         >
            <EventForm
               eventData={currentEvent} // This will be null for new events and contain data for edits
               onSave={onSaveEvent}
               onCancel={closeEditDialog}
            />
         </Dialog>
         <Dialog
            header={
               <span
                  style={{ color: 'white', textAlign: 'center', width: '100%' }}
               >
                  {currentEvent ? currentEvent.title : 'Event Details'}
               </span>
            }
            visible={detailsDialogVisible}
            style={{ width: '50vw' }}
            className="white-text-dialog"
            onHide={closeDetailsDialog}
            modal
         >
            {currentEvent && (
               <div>
                  <h2>{currentEvent.title}</h2>
                  <img
                     src={currentEvent.coverImage}
                     alt="Event Cover"
                     style={{
                        margin: '0 auto',
                        display: 'block',
                        height: '350px',
                     }}
                  />
                  <p>
                     <strong>Description:</strong> {currentEvent.description}
                  </p>
                  <p>
                     <strong>Start Time:</strong>{' '}
                     {new Date(currentEvent.startTime).toLocaleString()}
                  </p>
                  <p>
                     <strong>End Time:</strong>{' '}
                     {new Date(currentEvent.endTime).toLocaleString()}
                  </p>
                  <p>
                     <strong>Creator:</strong> {currentEvent.eventCreatorName}
                  </p>
                  <p>
                     <strong>Location:</strong>{' '}
                     {currentEvent.locationType === 'online'
                        ? 'Online'
                        : currentEvent.address}
                  </p>
               </div>
            )}
         </Dialog>
      </div>
   )
}

export default BigEventsDashboard
