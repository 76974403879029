import React from 'react'
import ReactDOM from 'react-dom'
import css from './EventsModal.module.css'
import { Button } from 'primereact/button'

export default function EventsModal({ isOpen, onClose, children }) {
   if (!isOpen) return null

   const handleOverlayClick = (e) => {
      e.stopPropagation() // Stop click from propagating to child elements
      onClose() // Call onClose when the overlay is clicked
   }

   const stopPropagation = (e) => e.stopPropagation() // Prevents the modal from closing when the modal content is clicked

   return ReactDOM.createPortal(
      <div
         className={css.modalOverlay}
         onClick={handleOverlayClick}
         id="modal-root"
      >
         <div className={css.modal} onClick={stopPropagation}>
            <Button
               icon="pi pi-times"
               onClick={onClose} // Ensure this calls the passed onClose function
               className={css.modalCloseButton}
            />
            {children}
         </div>
      </div>,
      document.getElementById('modal-root')
   )
}
