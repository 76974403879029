import React, { Suspense, lazy, useState } from 'react'
import { Col } from 'reactstrap'
import css from '../UserVideosDisplay.module.css'
import { toast } from 'react-toastify'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FaBars, FaTrash } from 'react-icons/fa'
import { Checkbox } from 'primereact/checkbox'
import useWelcomeVideo from '../../../../../../hooks/useWelcomeVideo'
import axios from 'axios'
import { handleError } from '../../../../../../utils/handleError'
import ProgressSpinner from '../../../../../../utils/ProgressSpinner'

const YouTubePlayerLazy = lazy(() =>
   import('../../../../../../components/youtubeCarousels/YoutubePlayer')
)

const UserVideosDisplay = ({ videoType }) => {
   const [isLoading, setLoading] = useState(false)
   const { userVideos, setUserVideos, userId, fetchUserVideos } =
      useWelcomeVideo(videoType)

   const handleSelectVideo = async (videoId) => {
      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/welcomeVideos/${videoId}/setMain`,
            { userId, videoType } // Include videoType if required by the backend
         )

         const updatedVideos = userVideos.map((video) => ({
            ...video,
            isSelected: video._id === videoId,
         }))
         setUserVideos(updatedVideos)
         fetchUserVideos()
         toast.success('Main video set successfully!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to set main video.')
      }
   }

   const onDragEnd = async (result) => {
      console.log('result', result)

      if (!result.destination) return // if dropped outside the list

      const reorderedVideos = Array.from(userVideos)
      const [reorderedItem] = reorderedVideos.splice(result.source.index, 1)
      reorderedVideos.splice(result.destination.index, 0, reorderedItem)

      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}welcomeVideos/updateOrder`,
            {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
                  // Include other headers like Authorization if needed
               },
               body: JSON.stringify({
                  userId: userId,
                  videos: reorderedVideos.map((video) => video._id), // Send only IDs
               }),
            }
         )

         if (!response.ok) {
            throw new Error('Server responded with an error.')
         }

         await response.json() // Assuming the server sends back JSON data
         setUserVideos(reorderedVideos) // Update the local state to reflect the new order
         fetchUserVideos()
         toast.success('Videos reordered successfully!')
      } catch (error) {
         handleError(error)
         handleError(error)
         toast.error('Failed to reorder videos.')
         // Optionally, revert to the original order in case of failure
         setUserVideos(userVideos)
      }
   }

   const handleDeleteVideo = async (videoId, index) => {
      if (!window.confirm('Are you sure you want to delete this video?')) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/welcomeVideos/${videoId}`
         )
         const updatedVideos = [...userVideos]
         updatedVideos.splice(index, 1)
         setUserVideos(updatedVideos)
         fetchUserVideos()
         toast.success('Video deleted successfully!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to delete video.')
      }
   }
   const getVideoIdFromUrl = (url) => {
      const regex =
         /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i
      const match = url.match(regex)
      return match ? match[1] : null
   }
   if (isLoading) {
      return <ProgressSpinner />
   }
   return (
      <>
         {userVideos.length > 0 ? (
            <Col>
               <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="videos" direction="horizontal">
                     {(provided) => (
                        <div
                           className={css.videoContainer}
                           ref={provided.innerRef}
                           {...provided.droppableProps}
                        >
                           <Suspense
                              fallback={<div>A carregar o vídeo...</div>}
                           >
                              {userVideos.map((video, index) => (
                                 <Draggable
                                    key={video._id}
                                    draggableId={`video-${index}`}
                                    index={index}
                                 >
                                    {(provided) => (
                                       <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={css.videoCard}
                                       >
                                          <div
                                             {...provided.dragHandleProps}
                                             style={{ marginRight: '10px' }}
                                          >
                                             <FaBars />
                                          </div>
                                          <YouTubePlayerLazy
                                             videoId={getVideoIdFromUrl(
                                                video.videoLink
                                             )}
                                             setWidth={180}
                                             setHeight={120}
                                          />
                                          <div
                                             style={{
                                                marginLeft: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                             }}
                                          >
                                             <FaTrash
                                                onClick={() =>
                                                   handleDeleteVideo(
                                                      video._id,
                                                      index
                                                   )
                                                }
                                                style={{ marginBottom: '10px' }}
                                             />
                                             <Checkbox
                                                checked={video.isSelected}
                                                onChange={() =>
                                                   handleSelectVideo(video._id)
                                                }
                                             />
                                          </div>
                                       </div>
                                    )}
                                 </Draggable>
                              ))}
                           </Suspense>
                        </div>
                     )}
                  </Droppable>
               </DragDropContext>
            </Col>
         ) : (
            <Col>Não foram encontrado vídeos.</Col>
         )}
      </>
   )
}

export default UserVideosDisplay
