import React, {
   createContext,
   useContext,
   useState,
   useEffect,
   useCallback,
} from 'react'
import axios from 'axios'

import { UserContext } from './useUserContext'

const PlaylistContext = createContext()

export const PlaylistProvider = ({ children }) => {
   const [playlistName, setPlaylistName] = useState('')
   const [playlistId, setPlaylistId] = useState('')
   const [videoLinks, setVideoLinks] = useState([])
   const [newVideoLink, setNewVideoLink] = useState('')
   const [selectedTopic, setSelectedTopic] = useState('')
   const [allPlaylists, setAllPlaylists] = useState([])
   const [isLoadingPlaylists, setIsLoadingPlaylists] = useState(false)
   const { user, isLoading: isUserLoading } = useContext(UserContext)
   const [mainPlaylistId, setMainPlaylistId] = useState('')
   const [selectedPlaylist, setSelectedPlaylist] = useState(null)
   const [playlistVisibility, setPlaylistVisibility] = useState(true)

   const topicOptions = [
      { label: 'ETFs', value: 'ETFs' },
      { label: 'Ações', value: 'Ações' },
      { label: 'REITs', value: 'REITs' },
      { label: 'Cripto Moedas', value: 'Cripto Moedas' },
      { label: 'Finanças Pessoais', value: 'Finanças Pessoais' },
      { label: 'Poupança', value: 'Poupança' },
      { label: 'Imobiliário', value: 'Imobiliário' },
      { label: 'Obrigações', value: 'Obrigações' },
      { label: 'Fundos mútuos', value: 'Fundos mútuos' },
      { label: 'Empreendedorismo', value: 'Empreendedorismo' },
      { label: 'Futuros e Opções', value: 'Futuros e Opções' },
      { label: 'Trading', value: 'Trading' },
   ]

   // Fetch playlists only if user is logged in and user data is available
   const fetchPlaylists = useCallback(async () => {
      if (!user?.id || isUserLoading) return

      setIsLoadingPlaylists(true) // Start loading playlists
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}playlistRoutes/regular/${user.id}`
         )
         setAllPlaylists(response.data)
      } catch (error) {
         console.error('Error loading playlists:', error)
         // toast.error('Failed to load playlists.')
      } finally {
         setIsLoadingPlaylists(false)
      }
   }, [user, isUserLoading])

   // Only call `fetchPlaylists` if the user is logged in
   useEffect(() => {
      if (!isUserLoading && user?.id) {
         fetchPlaylists()
      }
   }, [isUserLoading, user, fetchPlaylists])

   const handleCreatePlaylist = async () => {
      if (!playlistName.trim() || !selectedTopic || videoLinks?.length < 3) {
         // toast.error('Please fill all fields and add at least 3 videos.')
         return
      }
      try {
         const videoData = {
            playlistName,
            videoLinks,
            creator: user.id,
            playlistType: 'regular',
            topic: selectedTopic,
         }
         await axios.post(
            `${process.env.REACT_APP_API_URL}playlistRoutes/`,
            videoData
         )
         // toast.success('Playlist created successfully!')
         fetchPlaylists()
         clearForm()
      } catch (error) {
         console.error('Error creating playlist:', error)
         // toast.error('Error creating the playlist.')
      }
   }

   const handleUpdatePlaylist = async () => {
      if (!playlistId) return
      try {
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`,
            {
               playlistName,
               videoLinks,
               topic: selectedTopic,
            }
         )
         if (response.status === 200) {
            // toast.success('Playlist updated successfully!')
         }
         fetchPlaylists()
      } catch (error) {
         console.error('Error updating playlist:', error)
         // toast.error('Error updating the playlist.')
      }
   }

   const handleDeletePlaylist = async () => {
      if (!playlistId) {
         // toast.error('No playlist selected.')
         return
      }
      try {
         const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`
         )
         if (response.status === 200) {
            // toast.success('Playlist deleted successfully!')
            clearForm()
         }
         fetchPlaylists()
      } catch (error) {
         console.error('Error deleting playlist:', error)
         // toast.error('Error deleting the playlist.')
      }
   }

   const clearForm = () => {
      setPlaylistName('')
      setVideoLinks([])
      setSelectedTopic('')
      setNewVideoLink('')
      setSelectedPlaylist(null)
      // toast.info('Form cleared.')
   }

   return (
      <PlaylistContext.Provider
         value={{
            playlistName,
            setPlaylistName,
            playlistId,
            setPlaylistId,
            videoLinks,
            setVideoLinks,
            newVideoLink,
            setNewVideoLink,
            selectedTopic,
            setSelectedTopic,
            allPlaylists,
            handleCreatePlaylist,
            handleDeletePlaylist,
            handleUpdatePlaylist,
            clearForm,
            topicOptions,
            isLoadingPlaylists,
         }}
      >
         {children}
      </PlaylistContext.Provider>
   )
}

export const usePlaylist = () => useContext(PlaylistContext)
