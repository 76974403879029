import React from 'react'
import { usePlaylist } from '../../../context/PlaylistContext'
import { Col, Row } from 'reactstrap'

const PlaylistVisibilityToggle = () => {
   const { playlistVisibility, togglePlaylistVisibility } = usePlaylist()

   return (
      <Row>
         <Col>
            <label>
               Mostrar Playlist:
               <input
                  type="checkbox"
                  checked={playlistVisibility}
                  onChange={togglePlaylistVisibility}
               />
            </label>
         </Col>
      </Row>
   )
}

export default PlaylistVisibilityToggle
