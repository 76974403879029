import React from 'react'
import YouTube from 'react-youtube'
import css from './YoutubeCarousel.module.css'

const YouTubePlayer = React.memo(
   ({ videoId, onStart, setWidth, setHeight }) => {
      let props = videoId

      const opts = {
         width: setWidth,
         height: setHeight,
         playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0, // Set to 1 for autoplay
         },
      }

      if (!videoId) {
         console.error('YouTubePlayer requires a videoId', videoId)
         return <p>Video ID is not provided.</p>
      }

      return (
         <YouTube videoId={props} opts={opts} className={css.VideoDisplay} />
      )
   }
)

export default YouTubePlayer
