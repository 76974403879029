import React from 'react'
import { InputText, InputTextarea, InputNumber, Button } from 'primereact'
import { Col, Row } from 'reactstrap'
import css from './Courses.module.css'
import useCourseForm from './useCourseForm'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'

const CourseForm = ({
   existingCourse,
   onClose,
   createCourse,
   handleUpdateCourse,
}) => {
   const topicOptions = [
      'ETFs',
      'Ações',
      'REITs',
      'Cripto Moedas',
      'Finanças Pessoais',
      'Poupança',
      'Imobiliário',
      'Obrigações',
      'Fundos mútuos',
      'Empreendedorismo',
      'Futuros e Opções',
      'Trading',
   ]

   const statusOptions = ['published', 'draft']

   const formik = useCourseForm(
      existingCourse,
      createCourse,
      handleUpdateCourse,
      onClose
   )

   return (
      <form onSubmit={formik.handleSubmit}>
         <div style={{ display: 'flex' }} className={css.textColor}>
            <Row>
               {formik.isSubmitting && <ProgressSpinner />}
               <Col>
                  <div className={css.titulo}>Nome do Curso</div>
                  <InputText
                     id="courseName"
                     name="courseName"
                     value={formik.values.courseName}
                     onChange={formik.handleChange}
                     required
                  />
               </Col>
               <Col>
                  <div className={css.titulo}>Descrição</div>
                  <InputTextarea
                     id="description"
                     name="description"
                     value={formik.values.description}
                     onChange={formik.handleChange}
                     required
                     rows={5}
                  />
               </Col>
               <Col>
                  <div className={css.titulo}>Preço</div>
                  <InputNumber
                     id="price"
                     name="price"
                     value={formik.values.price}
                     onValueChange={(e) =>
                        formik.setFieldValue('price', e.value)
                     }
                     mode="currency"
                     currency="EUR"
                     required
                  />
               </Col>
            </Row>
            <Row>
               <Col>
                  <div className={css.titulo}>Escolher Tópico</div>
                  <select
                     id="topic"
                     name="topic"
                     value={formik.values.topic}
                     onChange={formik.handleChange}
                     className="p-inputtext p-component"
                  >
                     {topicOptions.map((option) => (
                        <option key={option} value={option}>
                           {option}
                        </option>
                     ))}
                  </select>
               </Col>
               <Col>
                  <div className={css.titulo}>Imagem da Capa</div>
                  <input
                     type="file"
                     accept="image/*"
                     name="bannerImage"
                     onChange={(event) =>
                        formik.setFieldValue(
                           'bannerImage',
                           event.currentTarget.files[0]
                        )
                     }
                  />
               </Col>
               <Col>
                  <div className={css.titulo}>Link para Comprar</div>
                  <InputText
                     id="purchaseLink"
                     name="purchaseLink"
                     value={formik.values.purchaseLink}
                     onChange={formik.handleChange}
                  />
               </Col>
               <Col>
                  <div className={css.titulo}>Escolher Estado</div>
                  <select
                     id="status"
                     name="status"
                     value={formik.values.status}
                     onChange={formik.handleChange}
                     className="p-inputtext p-component"
                  >
                     {statusOptions.map((option) => (
                        <option key={option} value={option}>
                           {option}
                        </option>
                     ))}
                  </select>
               </Col>
            </Row>
         </div>
         <Col>
            <Button type="submit" label="Submeter Curso" />
         </Col>
      </form>
   )
}

export default CourseForm
