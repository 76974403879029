import React, { useContext, useEffect, useState } from 'react'
import {
   InputText,
   Calendar,
   Editor,
   Dropdown,
   Button,
   InputNumber,
   InputMask,
} from 'primereact'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../../context/useUserContext'
import './EventCreationForm.css'
import { handleError } from '../../../../../../utils/handleError'
import { Col, Row } from 'react-bootstrap'
import { useEventContext } from '../../../../../../context/EventManagerContext'

const EventCreationForm = () => {
   const { user } = useContext(UserContext)
   const { editingEvent, fetchEvents, setIsEventModalOpen, handleSaveEvent } =
      useEventContext()

   if (!user) throw new Error('User context is not available')

   const [eventData, setEventData] = useState({
      name: '',
      description: '',
      topic: '',
      link: '',
      date: null,
      startTime: '',
      duration: null,
      bannerImage: null,
   })
   const [bannerImage, setBannerImage] = useState(null)

   const topicOptions = [
      { label: 'ETFs', value: 'ETFs' },
      // add other topics
   ]

   useEffect(() => {
      if (editingEvent) {
         setEventData({
            name: editingEvent.name || '',
            description: editingEvent.description || '',
            topic: editingEvent.topic || '',
            link: editingEvent.link || '',
            date: editingEvent.date ? new Date(editingEvent.date) : null,
            startTime: editingEvent.startTime || '',
            duration: editingEvent.duration || '',
            bannerImage: null, // Reset banner image on edit
         })
         // Handle the file separately if necessary
         if (editingEvent.bannerImage) {
            setBannerImage(editingEvent.bannerImage)
         }
      }
   }, [editingEvent])

   useEffect(() => {
      setEventData(
         editingEvent || {
            name: '',
            description: '',
            topic: '',
            link: '',
            date: null,
            startTime: '',
            duration: null,
            bannerImage: null,
         }
      )
   }, [editingEvent])

   const handleInputChange = (name, value) => {
      setEventData((prev) => ({ ...prev, [name]: value }))
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      if (
         !eventData.topic ||
         !eventData.startTime ||
         eventData.duration === null
      ) {
         toast.error('Please fill all required fields.')
         return
      }

      const formData = new FormData()
      Object.entries(eventData).forEach(([key, value]) => {
         if (value != null) formData.append(key, value)
      })
      if (bannerImage) {
         formData.append('bannerImage', bannerImage)
      }
      formData.append('creator', user.id)

      try {
         await handleSaveEvent(formData) // directly using handleSaveEvent
         resetForm()
         fetchEvents()

         toast.success(
            `Evento ${editingEvent ? 'atualizado' : 'criado'} com sucesso!`
         )
         setIsEventModalOpen(false) // Ensure to close modal on save
      } catch (error) {
         handleError(error)
         toast.error(
            `Erro ao ${
               editingEvent ? 'atualizar' : 'criar'
            } o evento. Tente novamente.`
         )
      }
   }

   const resetForm = () => {
      setEventData({
         name: '',
         description: '',
         topic: '',
         link: '',
         date: null,
         startTime: '',
         duration: null,
         bannerImage: null,
      })
      setBannerImage(null)
   }
   return (
      <form onSubmit={handleSubmit}>
         <Row className="event-form">
            <Col>
               <div className="form-field">
                  <label htmlFor="name" className="labelEventos">
                     Nome do Evento
                  </label>
                  <InputText
                     id="name"
                     value={eventData.name}
                     onChange={(e) => handleInputChange('name', e.target.value)}
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="description" className="labelEventos">
                     Descrição
                  </label>
                  <Editor
                     style={{ height: '100px' }}
                     value={eventData.description}
                     onTextChange={(e) =>
                        handleInputChange('description', e.htmlValue)
                     }
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="topic" className="labelEventos">
                     Selecionar Tema
                  </label>
                  <Dropdown
                     value={eventData.topic}
                     options={topicOptions}
                     onChange={(e) => handleInputChange('topic', e.value)}
                     placeholder="Select a Topic"
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="link" className="labelEventos">
                     Link do Evento
                  </label>
                  <InputText
                     id="link"
                     value={eventData.link}
                     onChange={(e) => handleInputChange('link', e.target.value)}
                  />
               </div>
            </Col>
            <Col>
               <div className="form-field">
                  <label htmlFor="date" className="labelEventos">
                     Data
                  </label>
                  <Calendar
                     id="date"
                     value={eventData.date}
                     onChange={(e) => handleInputChange('date', e.value)}
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="startTime" className="labelEventos">
                     Hora de Início
                  </label>
                  <InputMask
                     id="startTime"
                     mask="99:99"
                     value={eventData.startTime}
                     onChange={(e) => handleInputChange('startTime', e.value)}
                     placeholder="HH:mm"
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="duration" className="labelEventos">
                     Duração (minutos)
                  </label>
                  <InputNumber
                     id="duration"
                     value={eventData.duration}
                     onValueChange={(e) =>
                        handleInputChange('duration', e.value)
                     }
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="bannerImage" className="labelEventos">
                     Imagem do Banner
                  </label>
                  <input
                     type="file"
                     id="bannerImage"
                     onChange={(e) => setBannerImage(e.target.files[0])}
                     accept="image/*"
                  />
               </div>
            </Col>
         </Row>
         <Button label="Criar Evento" type="submit" />
      </form>
   )
}

export default EventCreationForm
