import React from 'react'
import { Row, Col } from 'reactstrap'
import WelcomeVideoCreatorCard from './WelcomeVideoManagementComponents/WelcomeVideoCreatorCard'
import WelcomeVideoCreatorPage from './WelcomeVideoManagementComponents/WelcomeVideoCreatorPage'

function WelcomeVideoSection() {
   return (
      <Row style={{ display: 'flex' }}>
         <Col>
            <WelcomeVideoCreatorCard />
         </Col>
         <Col>
            <WelcomeVideoCreatorPage />
         </Col>
      </Row>
   )
}

export default WelcomeVideoSection
