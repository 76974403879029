import React from 'react'
import '../Navbar.css'
import { FaEdit, FaSignOutAlt } from 'react-icons/fa' // Import icons from react-icons

const UserActions = ({ onEdit, onLogout }) => {
   return (
      <div>
         <FaEdit onClick={onEdit} className="navBarIcons" />
         <FaSignOutAlt onClick={onLogout} className="navBarIcons" />
      </div>
   )
}

export default UserActions
