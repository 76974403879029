import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import styles from './Courses.module.css' // assuming CSS modules
import ProgressSpinner from '../../../../../utils/ProgressSpinner'

const RenderHeader = ({ text }) => (
   <span className={styles.headerText}>{text}</span>
)

const ImageBodyTemplate = (rowData) => (
   <img
      src={rowData?.bannerImage}
      alt={`Cover for ${rowData?.courseName}`}
      style={{ width: '50px', height: '50px' }}
   />
)
const PriceBodyTemplate = (rowData) => {
   return `€${rowData.price.toFixed(2)}` // Ensure price is a number and format it as a decimal
}
const ActionBodyTemplate = ({ rowData, onEdit, onDelete }) => (
   <>
      <Button
         icon="pi pi-pencil"
         className="p-button-rounded p-button-success p-mr-2"
         onClick={() => onEdit(rowData)}
      />
      <Button
         icon="pi pi-trash"
         className="p-button-rounded p-button-warning"
         onClick={() => onDelete(rowData._id)}
      />
   </>
)

const CoursesList = ({
   courses,
   onEditCourse,
   onDeleteCourse,
   isLoading,
   error,
}) => {
   if (isLoading) {
      return <ProgressSpinner />
   }

   if (error) {
      return (
         <p className={styles.errorText}>
            Failed to load courses: {error.message}
         </p>
      )
   }

   if (!courses.length) {
      return <p className={styles.noCourses}>No courses available.</p>
   }

   return (
      <DataTable value={courses} className={styles.dataTable}>
         <Column
            header={<RenderHeader text="Capa" />}
            body={ImageBodyTemplate}
         />
         <Column field="courseName" header={<RenderHeader text="Nome" />} />
         <Column
            header={<RenderHeader text="Preço" />}
            body={PriceBodyTemplate}
         />
         <Column
            field="description"
            header={<RenderHeader text="Descrição" />}
         />
         <Column
            field="purchaseLink"
            header={<RenderHeader text="Comprar" />}
         />
         <Column field="status" header={<RenderHeader text="Estado" />} />
         <Column
            body={(rowData) => (
               <ActionBodyTemplate
                  rowData={rowData}
                  onEdit={onEditCourse}
                  onDelete={onDeleteCourse}
               />
            )}
            header={<RenderHeader text="Alterar" />}
         />
      </DataTable>
   )
}

export default CoursesList
