import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from '../../context/useUserContext'

const PrivateRoute = () => {
   const { user, isLoading } = useContext(UserContext)

   // Wait until loading finishes
   if (isLoading) {
      return <div>Loading...</div> // You can replace this with a spinner or more polished loader.
   }

   // Check if user is authenticated
   return user && user.login ? <Outlet /> : <Navigate to="/login" replace />
}

export default PrivateRoute
