import React, { createContext, useContext, useEffect, useState } from 'react'

const DashboardContext = createContext()

export const useDashboard = () => useContext(DashboardContext)

export const DashboardProvider = ({ children, userData }) => {
   const [socialMediaLinks, setSocialMediaLinks] = useState([])
   const [newLink, setNewLink] = useState('')
   const [formData, setFormData] = useState({
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      topics: [],
      role: '',
      bio: '',
      socialMediaLinks: [],
      profilePictureUrl: '',
   })

   // Function to initialize form data safely
   const initializeFormData = (userData) => {
      if (!userData) return // Early return if userData is null
      setFormData({
         username: userData.username || '',
         firstname: userData.firstname || '',
         lastname: userData.lastname || '',
         email: userData.email || '',
         topics: userData.topics || [],
         role: userData.role || '',
         bio: userData.bio || '',
         socialMediaLinks: userData.socialBarLinks || [],
         profilePictureUrl: userData.profilePictureUrl || '',
      })
      setSocialMediaLinks(userData.socialMediaLinks || [])
   }

   // Effect to initialize or update formData when userData changes
   useEffect(() => {
      initializeFormData(userData)
   }, [userData])

   const handleLinkChange = (e) => setNewLink(e.target.value)

   const handleAddLink = (link) => {
      if (link) {
         setSocialMediaLinks((prevLinks) => [...prevLinks, link])
      }
   }

   const updateFormData = (data) =>
      setFormData((prevFormData) => ({
         ...prevFormData,
         ...data,
      }))

   return (
      <DashboardContext.Provider
         value={{
            socialMediaLinks,
            setSocialMediaLinks,
            newLink,
            setNewLink,
            formData,
            setFormData,
            handleLinkChange,
            handleAddLink,
            updateFormData,
         }}
      >
         {children}
      </DashboardContext.Provider>
   )
}
