import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import css from './FileItem.module.css'
import { UserContext } from '../../../../../context/useUserContext'
import { Row } from 'reactstrap'
import FileVisibilityToggle from './FileVisibilityToggle'

import { FilesGrid } from './FilesGrid'
import { handleError } from '../../../../../utils/handleError'
import FileUpload from './FileUpload'

export default function FileManagementDashboard() {
   const [files, setFiles] = useState([])
   const [fetchError] = useState(null)
   const { user } = useContext(UserContext)
   const [isFileVisible, setIsFileVisible] = useState(false) // Assume false by default

   useEffect(() => {
      async function fetchFileVisibility() {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`
            )

            setIsFileVisible(response.data.files)
            toast.success('Visibility of files has been successfully updated!')
         } catch (error) {
            handleError(error)
            toast.error('Failed to fetch file visibility.')
         }
      }
      fetchFileVisibility()
   }, [user.id])

   useEffect(() => {
      async function fetchFiles() {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}fileRoutes/files/${user.id}`,
               {
                  headers: { Authorization: `Bearer ${user.accessToken}` },
               }
            )
            setFiles(response.status === 404 ? [] : response.data)
         } catch (error) {
            handleError(error)
            setFiles([])
            toast.error('Failed to fetch files.')
         }
      }
      fetchFiles()
   }, [user.id, isFileVisible]) // Re-fetch files when visibility changes
   const deleteFile = async (fileId) => {
      if (window.confirm('Tem a certeza que quer apagar este ficheiro?')) {
         try {
            await axios.delete(
               `${process.env.REACT_APP_API_URL}fileRoutes/files/${fileId}`,
               {
                  headers: { Authorization: `Bearer ${user.accessToken}` },
               }
            )
            // Optimistically remove the file from the UI
            const updatedFiles = files.filter((file) => file._id !== fileId)
            setFiles(updatedFiles)
            toast.success('Ficheiro apagado com sucesso')
         } catch (error) {
            handleError(error)
            toast.error('Falhou ao apagar o ficheiro')
         }
      }
   }
   return (
      <section className={css.tabContainer}>
         {fetchError && <p>Error loading files: {fetchError}</p>}
         <h1>Gestão de Ficheiros e Ebooks</h1>
         <FileVisibilityToggle
            isVisible={isFileVisible}
            setIsVisible={setIsFileVisible}
         />
         <div style={{ marginTop: '20px' }}>
            <Row>
               <FileUpload
                  onFileUploadSuccess={(file) =>
                     setFiles((prev) => [...prev, file])
                  }
               />
            </Row>
         </div>
         <Row>
            <div style={{ marginTop: '20px' }}>
               <h2>Lista de Ficheiros</h2>
               <FilesGrid
                  files={files}
                  onDelete={deleteFile}
                  showActions={true}
               />
            </div>
         </Row>
      </section>
   )
}
