import React from 'react'
import { Dropdown, Button } from 'primereact'
import { usePlaylist } from '../../../context/PlaylistContext'
import { Col, Row } from 'reactstrap'

const MainPlaylistSelector = () => {
   const {
      mainPlaylistId,
      handleSetMainPlaylist,
      playlistOptions,
      setMainPlaylistId,
   } = usePlaylist()

   const handleChange = (e) => {
      setMainPlaylistId(e.value) // Assuming `value` here directly corresponds to the option value
   }
   return (
      <Row>
         <Col>
            <Dropdown
               value={mainPlaylistId}
               options={playlistOptions} // This should be correct
               onChange={(e) => setMainPlaylistId(e.value.id)}
               placeholder="Select main playlist"
            />
            <Button
               label="Marcar como principal"
               size="small"
               onClick={() => handleSetMainPlaylist(mainPlaylistId)} // Pass mainPlaylistId directly
               style={{ marginLeft: '10px' }}
            />
         </Col>
      </Row>
   )
}

export default MainPlaylistSelector
