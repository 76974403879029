import React, {
   createContext,
   useContext,
   useState,
   useEffect,
   useCallback,
} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from './useUserContext'

const InsuranceContext = createContext()
//
export const InsuranceProvider = ({ children }) => {
   const [insurances, setInsurances] = useState(Array(12).fill(null)) // Store insurances for 12 months
   const [insuranceGoals, setInsuranceGoals] = useState({}) // Dynamically set goals
   const [insurancePremiums, setInsurancePremiums] = useState({})
   const [insuranceCoverages, setInsuranceCoverages] = useState({})
   const [insuranceDeductibles, setInsuranceDeductibles] = useState({})
   const [insuranceCategories, setInsuranceCategories] = useState([]) // Categories will be fetched from the backend
   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()) // Current month by default
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState(null)
   const { user, isLoading } = useContext(UserContext) // Get the logged-in user context

   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]

   // Helper function to get Authorization headers
   const getAuthHeaders = useCallback(() => {
      return {
         headers: {
            Authorization: `Bearer ${user?.accessToken}`,
         },
      }
   }, [user])

   // Fetch all insurance data including categories, premiums, coverages, and deductibles for the year
   const fetchYearlyData = useCallback(async () => {
      if (!user || !user.accessToken) {
         console.error('User is not authenticated')
         return // Stop if user is not authenticated
      }

      const currentYear = new Date().getFullYear()
      const apiUrl = `${process.env.REACT_APP_API_URL}users/user/${user.id}/year/${currentYear}`

      setLoading(true)
      try {
         const response = await axios.get(apiUrl, getAuthHeaders())
         const { insurances, premiums, coverages, deductibles, categories } =
            response.data

         // Update state with fetched data
         setInsurances(insurances || Array(12).fill(null))
         setInsurancePremiums(premiums || {})
         setInsuranceCoverages(coverages || {})
         setInsuranceDeductibles(deductibles || {})
         setInsuranceCategories(categories || []) // Get categories from the backend

         toast.success('Insurance data loaded successfully!')
      } catch (error) {
         console.error('Error fetching insurance data:', error)
         setError('Failed to fetch insurance data')
         // toast.error('Failed to load insurance data. Please try again.')
      } finally {
         setLoading(false)
      }
   }, [user, getAuthHeaders])

   // Fetch insurance objectives by month and year
   const fetchObjectives = async (month, year) => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/insuranceRoutes/user/${user.id}/month/${month}/year/${year}`,
            getAuthHeaders()
         )
         return response.data
      } catch (err) {
         console.error('Failed to fetch objectives:', err)
         return null
      }
   }

   // Update insurance objectives
   const updateObjectives = async (objectives) => {
      try {
         await axios.post(
            `${process.env.REACT_APP_API_URL}/insuranceRoutes`,
            {
               objectives,
               month: selectedMonth + 1,
               year: new Date().getFullYear(),
            },
            getAuthHeaders()
         )
         toast.success('Objectives updated successfully')
      } catch (err) {
         toast.error('Failed to update objectives')
      }
   }

   // Fetch insurance categories
   const fetchInsuranceCategories = useCallback(async () => {
      try {
         const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/insurance-categories`,
            getAuthHeaders()
         )

         // console.log('Fetched categories:', data.categories) // Make sure data.categories exists
         setInsuranceCategories(data.categories) // Setting categories directly
      } catch (error) {
         console.error('Failed to fetch insurance categories:', error)
      }
   }, [user, getAuthHeaders])

   // Add a new insurance category
   const addInsuranceCategory = async (newCategory) => {
      const updatedCategories = [...insuranceCategories, newCategory]
      setInsuranceCategories(updatedCategories)

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/insurance-categories`,
            { categories: updatedCategories },
            getAuthHeaders()
         )
         toast.success('Category added successfully!')
      } catch (error) {
         console.error('Failed to add category:', error)
         toast.error('Failed to add category.')
      }
   }

   // Delete an insurance category
   const deleteInsuranceCategory = async (categoryId) => {
      const updatedCategories = insuranceCategories.filter(
         (category) => category.id !== categoryId
      )
      setInsuranceCategories(updatedCategories)

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/insuranceRoutes/${user.id}/insurance-categories`,
            { categories: updatedCategories },
            getAuthHeaders()
         )
         toast.success('Category deleted successfully!')
      } catch (error) {
         console.error('Failed to delete category:', error)
         toast.error('Failed to delete category.')
      }
   }

   // Rename an insurance category
   const renameInsuranceCategory = async (categoryId, newName) => {
      const updatedCategories = insuranceCategories.map((category) =>
         category.id === categoryId ? { ...category, name: newName } : category
      )
      setInsuranceCategories(updatedCategories)

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/insuranceRoutes/${user.id}/insurance-categories`,
            { categories: updatedCategories },
            getAuthHeaders()
         )
         toast.success('Category renamed successfully!')
      } catch (error) {
         console.error('Failed to rename category:', error)
         toast.error('Failed to rename category.')
      }
   }

   // Trigger data fetching when user logs in
   useEffect(() => {
      if (user && user.accessToken && !isLoading) {
         fetchYearlyData()
         fetchInsuranceCategories()
      }
   }, [user, isLoading, fetchYearlyData, fetchInsuranceCategories])

   // Save or update insurance data
   const handleSave = async () => {
      const insuranceData = insurances[selectedMonth]
      const currentYear = new Date().getFullYear()

      try {
         const requestData = {
            premiums: insurancePremiums,
            coverages: insuranceCoverages,
            deductibles: insuranceDeductibles,
            categories: insuranceCategories, // Send updated categories
            month: selectedMonth + 1,
            year: currentYear,
            userId: user.id,
         }

         if (insuranceData && insuranceData._id) {
            await axios.put(
               `${process.env.REACT_APP_API_URL}/insuranceRoutes/${insuranceData._id}`,
               requestData,
               getAuthHeaders()
            )
            toast.success(
               `Insurance for ${months[selectedMonth]} updated successfully!`
            )
         } else {
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}/insuranceRoutes`,
               requestData,
               getAuthHeaders()
            )
            setInsurances((prev) => {
               const newInsurances = [...prev]
               newInsurances[selectedMonth] = response.data
               return newInsurances
            })
            toast.success(
               `Insurance for ${months[selectedMonth]} saved successfully!`
            )
         }
      } catch (error) {
         console.error('Error saving insurance:', error)
         toast.error(
            'An error occurred while saving the insurance. Please try again.'
         )
      }
   }

   // Delete insurance data for the selected month
   const handleDelete = async () => {
      const insuranceData = insurances[selectedMonth]
      if (!insuranceData || !insuranceData._id) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/insuranceRoutes/${insuranceData._id}`,
            getAuthHeaders()
         )
         setInsurances((prev) => {
            const newInsurances = [...prev]
            newInsurances[selectedMonth] = null
            return newInsurances
         })
         toast.success(
            `Insurance for ${months[selectedMonth]} deleted successfully!`
         )
      } catch (error) {
         console.error('Error deleting insurance:', error)
         toast.error(
            'An error occurred while deleting the insurance. Please try again.'
         )
      }
   }

   // Handle input changes for insurance premiums, coverages, and deductibles
   const handleChange = (e) => {
      const { name, value } = e.target
      setInsurancePremiums((prev) => ({
         ...prev,
         [name]: parseFloat(value) || 0,
      }))
   }

   const handleDeductibleChange = (e) => {
      const { name, value } = e.target
      setInsuranceDeductibles((prev) => ({
         ...prev,
         [name]: parseFloat(value) || 0,
      }))
   }

   // Handle updates to the insurance categories (dynamic)
   const handleCategoryChange = (updatedCategories) => {
      setInsuranceCategories(updatedCategories)
   }

   return (
      <InsuranceContext.Provider
         value={{
            insurances,
            insuranceGoals,
            insurancePremiums,
            insuranceCoverages,
            insuranceDeductibles,
            insuranceCategories, // Dynamic categories from the backend
            selectedMonth,
            handleSave,
            handleDelete,
            handleChange,
            fetchObjectives, // Expose fetchObjectives to context consumers
            updateObjectives,
            handleDeductibleChange,
            handleCategoryChange,
            addInsuranceCategory,
            deleteInsuranceCategory,
            renameInsuranceCategory,
            setInsuranceGoals,
            setInsurancePremiums,
            setInsuranceCoverages,
            setInsuranceDeductibles,
            setSelectedMonth,
            loading,
            error,
         }}
      >
         {children}
      </InsuranceContext.Provider>
   )
}

export const useInsurance = () => useContext(InsuranceContext)
