import React from 'react'
import { Row, Col } from 'reactstrap'
import useWelcomeVideo from '../../../../../../hooks/useWelcomeVideo'
import VideoDisplay from './VideoDisplay'
import VideoLinkInput from './VideoLinkInput'
import UserVideosDisplay from './UserVideosDisplay'

function WelcomeVideoCreatorCard() {
   const { currentVideo, userVideos, fetchUserVideos, setVideoLink } =
      useWelcomeVideo('creatorCard')

   return (
      <Row style={{ display: 'flex', flexDirection: 'column' }}>
         <Col>
            <h3 style={{ marginTop: '30px' }}>
               Vídeo de boas-vindas no Cartão de Apresentação
            </h3>
            <div style={{ marginTop: '10px' }}>
               {currentVideo ? (
                  <VideoDisplay
                     key={currentVideo._id}
                     videoLink={currentVideo.videoLink}
                  />
               ) : (
                  <p>
                     Não tem um vídeo de boas-vindas. Por favor carregue um
                     vídeo do Youtube.
                  </p>
               )}
            </div>
         </Col>
         <Col>
            <div className="subtitulo">Adicionar novo vídeo de boas-vindas</div>
            <VideoLinkInput
               userVideos={userVideos}
               fetchUserVideos={fetchUserVideos}
               onLinkSubmit={setVideoLink}
               videoType="creatorCard"
            />
         </Col>
         <Col>
            <h3 style={{ marginTop: '30px' }}>Gerir Vídeos</h3>
            <UserVideosDisplay videoType="creatorCard" />
         </Col>
      </Row>
   )
}

export default WelcomeVideoCreatorCard
