import React, { useContext } from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import { UserContext } from '../../context/useUserContext'
import toolsDataByRole from '../../utils/toolsDataByRole'
import css from './ToolIndex.module.css'

function ToolTypes() {
   const { user } = useContext(UserContext)
   const userRole = user.role

   const userTools = toolsDataByRole[userRole] || {
      personalFinanceTools: [],
      investingTools: [],
   }

   return (
      <div>
         <Row className={css.tabContainer}>
            <div className={css.tabContainer}>
               <nav className={css.tabNavigation}>
                  {userTools.investingTools.length > 0 && (
                     <NavLink
                        to="/ferramentas/investingTools/etf"
                        className={({ isActive }) =>
                           isActive ? css.activeTab : css.tab
                        }
                     >
                        Ferramentas de Análise
                     </NavLink>
                  )}
                  {userTools.personalFinanceTools.length > 0 && (
                     <NavLink
                        to="/ferramentas/personalFinanceTools/emergencyfund"
                        className={({ isActive }) =>
                           isActive ? css.activeTab : css.tab
                        }
                     >
                        Ferramentas Finanças Pessoais
                     </NavLink>
                  )}
               </nav>
            </div>
            <div className={css.tabContent}>
               <Outlet />
            </div>
         </Row>
      </div>
   )
}

export default ToolTypes
