// MobileNavPopup.js
import React from 'react'
import { NavLink } from 'react-router-dom'
import css from './Layout.module.css'
import { Button } from 'reactstrap'

const MobileNavPopup = ({ isOpen, onClose }) => {
   if (!isOpen) return null

   return (
      <div className={css.mobileNavPopup}>
         <Button className={css.closeButton} onClick={onClose}>
            &times;
         </Button>
         <NavLink to="/home" className={css.tab} onClick={onClose}>
            Página Inicial
         </NavLink>
         <NavLink
            to="/criadoresfinanceiros"
            className={css.tab}
            onClick={onClose}
         >
            Educadores
         </NavLink>
         <NavLink to="/eventosparcerias" className={css.tab} onClick={onClose}>
            Eventos de Parcerias
         </NavLink>
         <NavLink
            to="/ferramentas/investingTools/etf"
            className={css.tab}
            onClick={onClose}
         >
            Ferramentas
         </NavLink>
         <NavLink to="/glossario" className={css.tab} onClick={onClose}>
            Glossário
         </NavLink>
         <NavLink to="/cursos" className={css.tab} onClick={onClose}>
            Cursos e Formações
         </NavLink>
         {/* <NavLink to="/artigos" className={css.tab} onClick={onClose}>
            Artigos
         </NavLink> */}
         <NavLink to="/livros" className={css.tab} onClick={onClose}>
            Literacia Financeira
         </NavLink>
      </div>
   )
}

export default MobileNavPopup
