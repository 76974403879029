// src/data/toolsDataByRole.js

const toolsDataByRole = {
   CreatorUser: {
      personalFinanceTools: [],
      investingTools: [
         {
            url: 'etf',
            term: 'Análise de ETFs',
         },
         {
            url: 'reits',
            term: 'Valor Intrínseco de Reits',
         },
         {
            url: 'stocks',
            term: 'Valor Intrínseco de Stocks',
         },
      ],
   },
   RegularUser: {
      personalFinanceTools: [
         {
            url: 'emergencyfund',
            term: 'Fundo de Emergencia',
         },
         {
            url: 'savings',
            term: 'Controlo de Despesas e Poupança',
         },
      ],
      investingTools: [
         {
            url: 'etf',
            term: 'Análise de ETFs',
         },
         {
            url: 'reits',
            term: 'Valor Intrínseco de Reits',
         },
         {
            url: 'stocks',
            term: 'Valor Intrínseco de Stocks',
         },
      ],
   },
   AdminUser: {
      personalFinanceTools: [
         {
            url: 'emergencyfund',
            term: 'Fundo de Emergencia',
         },
         {
            url: 'savings',
            term: 'Controlo de Despesas e Poupança',
         },
      ],
      investingTools: [
         {
            url: 'etf',
            term: 'Análise de ETFs',
         },
         {
            url: 'reits',
            term: 'Valor Intrínseco de Reits',
         },
         {
            url: 'stocks',
            term: 'Valor Intrínseco de Stocks',
         },
      ],
   },
}

export default toolsDataByRole
