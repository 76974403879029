import React from 'react'
import EventsDashboard from './EventsDashboard'
import EventCreationForm from './EventCreationForm'
import EventsModal from './EventsModal'
import { useEventContext } from '../../../../../../context/EventManagerContext'

const EventManagementPage = () => {
   const { isEventModalOpen, setIsEventModalOpen } = useEventContext()
   return (
      <div>
         <EventsDashboard />
         <EventsModal
            isOpen={isEventModalOpen}
            onClose={() => setIsEventModalOpen(false)}
         >
            <EventCreationForm />
         </EventsModal>
      </div>
   )
}

export default EventManagementPage
